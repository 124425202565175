import Vue from 'vue'
import axios from 'axios'
import VueAxios from 'vue-axios'
import JwtService from '@/core/services/jwt.service'
import Swal from 'sweetalert2'

import { LOGOUT } from '@/core/services/store/auth.module'
import store from './store'
import router from '../../router'
import { base_url } from '../../const'

/**
 * Service to call HTTP request via Axios
 */
const ApiService = {
  init() {
    Vue.use(VueAxios, axios)
    // Vue.axios.defaults.baseURL = "http://localhost:3000/";
    Vue.axios.defaults.baseURL = base_url
  },

  /**
   * Set the default HTTP request headers
   */
  setHeader() {
    Vue.axios.defaults.headers.common['Authorization'] = `Bearer ${JwtService.getToken()}`
  },

  query(resource, params) {
    return Vue.axios.get(resource, params).catch(error => {
      throw new Error(`[KT] ApiService ${error}`)
    })
  },

  /**
   * Send the GET HTTP request
   * @param resource
   * @param slug
   * @returns {*}
   */
  get(resource) {
    return Vue.axios
      .get(`${resource}`)
      .then(res => Promise.resolve(res))
      .catch(err => {
        if (err.response.status == 401) {
          store.dispatch(LOGOUT)
          router.push('/login')
          Swal.fire('Session is not valid!', '', 'error')
        } else {
          return Promise.reject(err)
        }
      })
  },

  /**
   * Set the POST HTTP request
   * @param resource
   * @param params
   * @param config
   * @returns {*}
   */
  post(resource, params) {
    console.log(`resource`, resource)
    console.log(`params`, params)
    return Vue.axios
      .post(`${resource}`, params)
      .then(res => Promise.resolve(res))
      .catch(err => {
        console.log(`${resource} err`, err)
        return Promise.reject(err)
        // if (err.response.status == 401) {
        //   store.dispatch(LOGOUT)
        //   router.push('/login')
        //   Swal.fire('Session is not valid!', '', 'error')
        // } else {
        //   return Promise.reject(err)
        // }
      })
  },

  uploadBlob(resource, file, onUploadProgress) {
    let formData = new FormData()
    formData.append('file', file)
    return Vue.axios.post(`${resource}`, formData, {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
      onUploadProgress,
    })
  },

  /**
   * Send the UPDATE HTTP request
   * @param resource
   * @param slug
   * @param params
   * @returns {IDBRequest<IDBValidKey> | Promise<void>}
   */
  update(resource, slug, params) {
    return Vue.axios
      .put(`${resource}/${slug}`, params)
      .then(res => Promise.resolve(res))
      .catch(err => {
        if (err.response.status == 401) {
          store.dispatch(LOGOUT)
          router.push('/login')
          Swal.fire('Session is not valid!', '', 'error')
        } else {
          return Promise.reject(err)
        }
      })
  },

  /**
   * Send the PUT HTTP request
   * @param resource
   * @param params
   * @returns {IDBRequest<IDBValidKey> | Promise<void>}
   */
  put(resource, params) {
    return Vue.axios
      .put(`${resource}`, params)
      .then(res => Promise.resolve(res))
      .catch(err => {
        if (err.response.status == 401) {
          store.dispatch(LOGOUT)
          router.push('/login')
          Swal.fire('Session is not valid!', '', 'error')
        } else {
          return Promise.reject(err)
        }
      })
  },

  /**
   * Send the DELETE HTTP request
   * @param resource
   * @returns {*}
   */
  delete(resource, data) {
    console.log(`data`, data)
    console.log(`resource`, resource)
    return Vue.axios
      .delete(resource, data)
      .then(res => Promise.resolve(res))
      .catch(err => {
        console.log(`err`, err)
        if (err.response.status == 401) {
          store.dispatch(LOGOUT)
          router.push('/login')
          Swal.fire('Session is not valid!', '', 'error')
        } else {
          return Promise.reject(err)
        }
      })
  },
}

export default ApiService

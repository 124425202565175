import Vue from 'vue'
import * as VueGoogleMaps from 'vue2-google-maps'

Vue.use(VueGoogleMaps, {
  load: {
    apiKey: 'AIzaSyAQHvwam0oJJoRtsKPBqFMgn2i9mPLBsT8',
    libraries: 'places',
  },
  installComponents: true,
})

Vue.component('google-map', VueGoogleMaps.Map)
Vue.component('google-kml-layer', VueGoogleMaps.KmlLayer)

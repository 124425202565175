import ApiService from '@/core/services/api.service'
import JwtService from '@/core/services/jwt.service'
import UsersServices from '../users/index'

// action types
export const VERIFY_AUTH = 'verifyAuth'
export const LOGIN = 'login'
export const LOGOUT = 'logout'
export const FORGOT_PASSWORD = 'forgotPassword'
export const REGISTER = 'register'
export const UPDATE_PASSWORD = 'updateUser'
export const VERIFY_ACCOUNT = 'verifyAccount'
export const CONFIRM_CHANGE_PASSWORD = 'confirmChangePassword'

// mutation types
export const PURGE_AUTH = 'logOut'
export const SET_AUTH = 'setUser'
export const SET_PASSWORD = 'setPassword'
export const SET_ERROR = 'setError'
export const SET_STATUS = 'setStatus'

const state = {
  status: 0,
  errors: [],
  user: {},
  isAuthenticated: !!JwtService.getToken(),
}

const getters = {
  currentUser(state) {
    return state.user
  },
  isAuthenticated(state) {
    return state.isAuthenticated
  },
  getErrors(state) {
    return state.errors
  },
  getStatus(state) {
    return state.status
  },
}

const actions = {
  [LOGIN](context, credentials) {
    return new Promise((resolve, reject) => {
      ApiService.post('auth/login', credentials)
        .then(async ({ data }) => {
          const id = 'scooble_login_id'
          window.localStorage.setItem(id, data._id)
          context.commit(SET_AUTH, data)
          const user_id = window.localStorage.getItem('scooble_login_id')

          ApiService.setHeader()

          const { data: user } = await ApiService.get('users/' + user_id)
          window.localStorage.setItem('scooble_user', JSON.stringify(user))
          console.log(`data`, user)
          if (user?.role?.name !== 'normal') {
            return resolve(data)
          }
          resolve(false)
        })
        .catch(err => {
          const { response } = err
          reject(response)
          if (!response) {
            context.commit(SET_ERROR, 'An error has occurred. Please try again later.')
          } else {
            if (response.data.message === 'Verification Error') {
              return context.commit(
                SET_ERROR,
                'Please follow the link in  the verification email we sent you to complete your registration.'
              )
            }
            context.commit(SET_ERROR, response.data.message)
          }
        })
    })
  },
  [LOGOUT](context) {
    context.commit(PURGE_AUTH)
    window.localStorage.removeItem('scooble_login_id')
    window.localStorage.removeItem('scooble_user')
    window.sessionStorage.removeItem('scooble_check_token')
  },
  async [FORGOT_PASSWORD](context, credentials) {
    console.log(`context`, context)
    console.log(`credentials`, credentials)
    const sendForgotEmail = (await UsersServices.forgotPassword(credentials.email, 'de')).data
    console.log(`sendForgotEmail`, sendForgotEmail)
    if (sendForgotEmail.status == 200) {
      // context.commit(SET_STATUS, sendForgotEmail.status)
      // context.commit(SET_ERROR, 'Success')
      return sendForgotEmail
    }
    context.commit(SET_ERROR, 'Unauthorized')
    return false
    // ApiService.post('auth/forgotPassword', credentials)
    //   .then(response => {
    //     resolve(response)
    //     if (response.status == 200) {
    //       context.commit(SET_STATUS, response.status)
    //       context.commit(SET_ERROR, 'Success')
    //     }
    //   })
    //   .catch(reason => {
    //     context.commit(SET_STATUS, reason.response.status)
    //     if (reason.response.status == 401) {
    //       context.commit(SET_ERROR, 'Unauthorized')
    //     }
    //     reject(reason)
    //   })
  },
  [VERIFY_AUTH](context) {
    if (JwtService.getToken()) {
      ApiService.setHeader()

      ApiService.post('auth/checkToken/', {
        token: JwtService.getToken(),
      })
        .then(({ data }) => {
          window.sessionStorage.setItem('scooble_checkToken', JSON.stringify(data))
          // context.commit(SET_AUTH, data);
        })
        .catch(({ response }) => {
          context.commit(SET_ERROR, response.data.errors)
        })
    } else {
      context.commit(PURGE_AUTH)
    }
  },
  async [UPDATE_PASSWORD](context, payload) {
    console.log(`payload`, payload)
    const { password, userId, newPassword, deviceLocale } = payload
    console.log(`password`, password)
    return await UsersServices.changePassword(userId, password, newPassword, deviceLocale).then(res => {
      console.log(`res`, res)
      if (res) {
        context.commit(SET_PASSWORD, newPassword)
      }
      return res
    })
    // return ApiService.put('password', password).then(({ data }) => {
    //   context.commit(SET_PASSWORD, data)
    //   return data
    // })
  },
  async [CONFIRM_CHANGE_PASSWORD](context, payload) {
    const { userId, code, email, password } = payload
    const checkCode = (await UsersServices.checkForgotPasswordCode(userId, code)).data
    console.log(`checkCode`, checkCode)
    if (!checkCode) {
      context.commit(SET_STATUS, '401')
      return context.commit(SET_ERROR, 'invalid code.')
    }

    const changePassword = (await UsersServices.forgotPasswordChangePassword(email, password, 'de')).data

    if (!changePassword) {
      return context.commit(SET_ERROR, 'unexpected password')
    }
    context.commit(SET_PASSWORD, password)
    context.commit(SET_ERROR, '')
    return true
  },
  [VERIFY_ACCOUNT](context, verificationCode) {
    return ApiService.post('auth/verifyAccount', { verificationCode })
  },
}

const mutations = {
  [SET_ERROR](state, error) {
    state.errors = error
  },
  [SET_STATUS](state, status) {
    state.status = status
  },
  [SET_AUTH](state, user) {
    state.isAuthenticated = true
    state.user = user
    state.errors = {}
    JwtService.saveToken(state.user.token)
  },
  [SET_PASSWORD](state, password) {
    state.user.password = password
  },

  [PURGE_AUTH](state) {
    state.isAuthenticated = false
    state.user = {}
    state.errors = {}
    JwtService.destroyToken()
  },
}

export default {
  state,
  actions,
  mutations,
  getters,
}

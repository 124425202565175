import Vue from 'vue'
import Router from 'vue-router'
// import DisclaimerServices from './core/services/disclaimers'

Vue.use(Router)

export default new Router({
  routes: [
    {
      path: '/',
      redirect: '/dashboard',
      component: () => import('@/view/layout/Layout'),
      children: [
        {
          path: '/dashboard',
          name: 'dashboard',
          meta: {
            title: 'Admin Dashboard',
            subtitle: 'users, events and messages',
          },
          component: () => import('@/view/pages/Dashboard.vue'),
        },
        {
          path: '/builder',
          name: 'builder',
          component: () => import('@/view/pages/Builder.vue'),
        },
        {
          path: '/user',
          name: 'user',
          meta: {
            title: 'Overview',
          },
          component: () => import('@/view/pages/user/UserOverview.vue'),
        },
        {
          path: '/user/detail/:id',
          name: 'user-detail',
          meta: {
            title: 'User Detail',
          },
          component: () => import('@/view/pages/user/UserOverviewDetail.vue'),
        },
        {
          path: '/events/new',
          name: 'event-new',
          meta: {
            title: 'New Event',
          },
          component: () => import('@/view/pages/events/New.vue'),
        },
        {
          path: '/events',
          name: 'events',
          meta: {
            title: 'Overview',
          },
          component: () => import('@/view/pages/events/Events.vue'),
        },

        {
          path: '/profile',
          name: 'profile',
          meta: {
            title: 'Profile',
          },
          component: () => import('@/view/pages/Profile.vue'),
        },
        {
          path: '/sponsors/new',
          name: 'sponsors-new',
          meta: {
            title: 'New Sponsor',
          },
          component: () => import('@/view/pages/sponsors/New.vue'),
        },
        {
          path: '/sponsors/overview',
          name: 'sponsors-overview',
          meta: {
            title: 'Overview',
          },
          component: () => import('@/view/pages/sponsors/Overview.vue'),
        },
        {
          path: '/notifications/new',
          name: 'notifications-overview',
          meta: {
            title: 'New Message',
          },
          component: () => import('@/view/pages/notifications/New.vue'),
        },
        {
          path: '/notifications/overview',
          name: 'notifications-overview',
          meta: {
            title: 'Overview',
          },
          component: () => import('@/view/pages/notifications/Overview.vue'),
        },
        {
          path: '/disclaimers/dataprotection',
          name: 'disclaimers-data-protection',
          meta: {
            title: 'Data Protection',
          },
          component: () => import('@/view/pages/disclaimers/DataProtection.vue'),
        },
        {
          path: '/disclaimers/imprint',
          name: 'disclaimers-imprint',
          meta: {
            title: 'Imprint',
          },
          component: () => import('@/view/pages/disclaimers/Imprint.vue'),
        },
        {
          path: '/disclaimers/termsandconditions',
          name: 'disclaimers-terms-conditions',
          meta: {
            title: 'Terms And Conditions',
          },
          component: () => import('@/view/pages/disclaimers/TermsAndConditions.vue'),
        },
        {
          path: '/disclaimers/declarationofconsent',
          name: 'disclaimers-declaration-consent',
          meta: {
            title: 'Declaration Of Consent',
          },
          component: () => import('@/view/pages/disclaimers/DeclarationOfConsent.vue'),
        },
        {
          path: '/disclaimers/info-disclaimers/:id',
          name: 'info-disclaimers',
          meta: {
            title: 'Data Protection',
          },
          component: () => import('@/view/pages/disclaimers/InfoDisclaimers.vue'),
          props: route => ({ id: route.params.id }),
        },
        {
          path: '/disclaimers/newdisclaimer',
          name: 'new-disclaimer',
          meta: {
            title: 'Data Protection',
          },
          component: () => import('@/view/pages/disclaimers/NewDisclaimer.vue'),
          props: route => ({ id: route.params.id }),
        },
        {
          path: '/contracts/new',
          name: 'contracts-new',
          meta: {
            title: 'New',
          },
          component: () => import('@/view/pages/contracts/New.vue'),
        },
        {
          path: '/contracts/overview',
          name: 'contracts-overview',
          meta: {
            title: 'Overview',
          },
          component: () => import('@/view/pages/contracts/Overview.vue'),
        },
        {
          path: '/contracts/edit/:id',
          name: 'contracts-edit',
          meta: {
            title: 'Edit',
          },
          component: () => import('@/view/pages/contracts/Edit.vue'),
        },
        {
          path: '/maps/new',
          name: 'maps-new',
          meta: {
            title: 'New',
          },
          component: () => import('@/view/pages/maps/New.vue'),
        },
        {
          path: '/maps/overview',
          name: 'maps-overview',
          meta: {
            title: 'Overview',
          },
          component: () => import('@/view/pages/maps/Overview.vue'),
        },
        {
          path: '/pages/new',
          name: 'pages-new',
          meta: {
            title: 'New',
          },
          component: () => import('@/view/pages/pages/New.vue'),
        },
        {
          path: '/pages/edit/:id',
          name: 'pages-edit',
          meta: {
            title: 'Edit',
          },
          component: () => import('@/view/pages/pages/Edit.vue'),
        },
        {
          path: '/pages/overview',
          name: 'pages-overview',
          meta: {
            title: 'Overview',
          },
          component: () => import('@/view/pages/pages/Overview.vue'),
        },
        {
          path: '/vue-bootstrap',
          name: 'vue-bootstrap',
          component: () => import('@/view/pages/vue-bootstrap/VueBootstrap.vue'),
          children: [
            {
              path: 'alert',
              name: 'vue-bootstrap-alert',
              component: () => import('@/view/pages/vue-bootstrap/Alert.vue'),
            },
            {
              path: 'badge',
              name: 'vue-bootstrap-badge',
              component: () => import('@/view/pages/vue-bootstrap/Badge.vue'),
            },
            {
              path: 'button',
              name: 'vue-bootstrap-button',
              component: () => import('@/view/pages/vue-bootstrap/Button.vue'),
            },
            {
              path: 'button-group',
              name: 'vue-bootstrap-button-group',
              component: () => import('@/view/pages/vue-bootstrap/ButtonGroup.vue'),
            },
            {
              path: 'button-toolbar',
              name: 'vue-bootstrap-button-toolbar',
              component: () => import('@/view/pages/vue-bootstrap/ButtonToolbar.vue'),
            },
            {
              path: 'card',
              name: 'vue-bootstrap-card',
              component: () => import('@/view/pages/vue-bootstrap/Card.vue'),
            },
            {
              path: 'carousel',
              name: 'vue-bootstrap-carousel',
              component: () => import('@/view/pages/vue-bootstrap/Carousel.vue'),
            },
            {
              path: 'collapse',
              name: 'vue-bootstrap-collapse',
              component: () => import('@/view/pages/vue-bootstrap/Collapse.vue'),
            },
            {
              path: 'dropdown',
              name: 'vue-bootstrap-dropdown',
              component: () => import('@/view/pages/vue-bootstrap/Dropdown.vue'),
            },
            {
              path: 'embed',
              name: 'vue-bootstrap-embed',
              component: () => import('@/view/pages/vue-bootstrap/Embed.vue'),
            },
          ],
        },
      ],
    },
    {
      path: '/custom-error',
      name: 'error',
      component: () => import('@/view/pages/error/Error.vue'),
      children: [
        {
          path: 'error-1',
          name: 'error-1',
          component: () => import('@/view/pages/error/Error-1.vue'),
        },
        {
          path: 'error-2',
          name: 'error-2',
          component: () => import('@/view/pages/error/Error-2.vue'),
        },
        {
          path: 'error-3',
          name: 'error-3',
          component: () => import('@/view/pages/error/Error-3.vue'),
        },
        {
          path: 'error-4',
          name: 'error-4',
          component: () => import('@/view/pages/error/Error-4.vue'),
        },
        {
          path: 'error-5',
          name: 'error-5',
          component: () => import('@/view/pages/error/Error-5.vue'),
        },
        {
          path: 'error-6',
          name: 'error-6',
          component: () => import('@/view/pages/error/Error-6.vue'),
        },
      ],
    },
    {
      name: 'email-verify',
      path: '/email-verify/:verificationCode',
      component: () => import('@/view/pages/auth/EmailVerify'),
    },
    {
      path: '/',
      component: () => import('@/view/pages/auth/login_pages/Login-1'),
      children: [
        {
          name: 'login',
          path: '/login',
          component: () => import('@/view/pages/auth/login_pages/Login-1.vue'),
        },
        {
          name: 'forgot',
          path: '/forgot',
          component: () => import('@/view/pages/auth/login_pages/Login-1'),
        },
        {
          name: 'verify',
          path: '/verify',
          component: () => import('@/view/pages/auth/login_pages/Login-1'),
        },
      ],
    },
    {
      path: '*',
      redirect: '/404',
    },
    {
      // the 404 route, when none of the above matches
      path: '/404',
      name: '404',
      component: () => import('@/view/pages/error/Error-1.vue'),
    },
  ],
  mode: 'history',
})

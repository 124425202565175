import ApiService from '../api.service'
const endPoint = '/users'
const { put, post } = ApiService

class UsersServices {
  async update(data) {
    //   data :{ profile, _id: loggedUserDetail?._id, deviceLocale }
    console.log(`data`, data)
    return await put(`${endPoint}`, data)
  }

  async forgotPassword(email, deviceLocale) {
    return await post(`/auth/forgot-password`, { email, deviceLocale })
  }

  async checkForgotPasswordCode(userId, code) {
    return await post(`/auth/resetcode-validation`, { userId, resetCode: code })
  }

  async forgotPasswordChangePassword(email, password, deviceLocale) {
    return await post(`/auth/approve-password`, { email, password, deviceLocale })
  }

  async delete(id) {
    return await ApiService.delete(`${endPoint}/${id}`)
  }

  async changePassword(userId, password, newPassword, deviceLocale) {
    return await post(`${endPoint}/change-password`, { userId, password, newPassword, deviceLocale })
  }
}
export default new UsersServices()
